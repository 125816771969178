import "./App.css";
import WebSite from "./publicComponents/WebSite";

function App() {
  return (
    <>
      <WebSite />
    </>
  );
}

export default App;
